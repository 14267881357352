<template>
  <div></div>
</template>

<script>
import { successTransaction } from '@/services/braintree.service'
import { notification } from 'ant-design-vue'
export default {
  name: 'SuccessTransaction',
  created() {
    this.init(this.$route.query.token, this.$route.query.subscription_id)
  },
  methods: {
    async init(token, subscription_id) {
      try {
        const data = await successTransaction({ token, subscription_id })

        notification.success({
          message: 'Successfully subscribed!',
        })

        if (localStorage.getItem('redirect-quiz-paypal-success')) {
          this.$router.push('/quiz?redirect=Home')
          localStorage.removeItem('redirect-quiz-paypal-success')
          return
        }

        this.$router.push({
          path: '/home',
          query: {
            event: 'purchase',
            package_id: data?.package_id,
          },
        })
      } catch (err) {
        this.$router.push('/home')
      }
    },
  },
}
</script>

<style></style>
